<template>
    <div class="ReportViewMain" v-loading="isLoading">
        <div class="Tools">
            <el-button class="Common_Margin" @click="Router.go(0)">{{lang['html.Router']}}</el-button>
            <el-button class="Common_Margin" @click="Search">{{lang['html.Search']}}</el-button>
            <el-button class="Common_Margin" @click="OutDataToFile">{{lang['html.OutDataToFile']}}</el-button>
<!--            <el-button class="Common_Margin" @click="Search">排序数据</el-button>-->
            <slot name="sort" class="Common_Margin" />
            <el-checkbox v-model="IsDeep" :label="lang['html.checkbox']" size="large" @change="changeDeep"/>
        </div>

        <Report_Table
            style="margin-top: 10px"
            :column="props.options.TableColumn"
            :tableData="TableData"
        />
        <Report_Pages
            :total="PagesOptions.total"
            @PagesEvent="PagesEvent"
            ref="refsReportPages"
        />
    </div>

    <el-dialog
        v-model="SearchOptions.view"
        title="Search"
        width="30%"
    >
        <el-input v-model="SearchOptions.str" :placeholder="lang['html.input.placeholder']" />
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="SearchOptions.view = false">{{lang['html.checkbox.false']}}</el-button>
            <el-button type="primary" @click="SearchData">
              {{lang['html.checkbox.SearchData']}}
            </el-button>
          </span>
        </template>
    </el-dialog>
</template>

<script setup>
import langLib from '@/lang/index';
import Report_Table from "./Report_Table";
import {useRouter} from "vue-router";
import {ref, defineExpose,defineEmits, defineProps, toRaw,} from "vue";
import Report_Pages from "./Report_Pages";
import common from "../../utils/common";
import {ElMessage} from "element-plus";
const props = defineProps(['options']);
const emits = defineEmits(['changeDeep'])
const Router = useRouter();
const isLoading = ref(true);
const lang = langLib.getLang('components','ReportView')

const TableData = ref([]);
const ViewDataArr = ref([]);

const PagesOptions = ref({
    total:0,
    page:1,
    size:20,
})
const refsReportPages = ref();
const SendPagesOptions = () => {
    refsReportPages.value.recvOptions(toRaw(PagesOptions.value))
}
const SetTableDataArr = (arr) => {
    PagesOptions.value.page = 1;
    PagesOptions.value.total = arr.length;
    ViewDataArr.value = arr;
    SendPagesOptions()
    ChangePagesOptions();
    isLoading.value = false;
    ElMessage({
        message: lang['SetTableDataArr.tips'] + ":" + PagesOptions.value.total,
        type: 'success',
    })
}


const PagesEvent = (obj) => {
    let {page} = obj;
    PagesOptions.value.page = page;
    ChangePagesOptions();
    // console.log('PagesEvent',page,obj)
}
const ChangePagesOptions = () => {
    let {page,size} = PagesOptions.value;
    let max = page * size;
    let min = max - size;
    TableData.value = ViewDataArr.value.slice(min,max)
}
defineExpose({
    SetTableDataArr
})

const OutDataToFile = () => {
    let line = '\r\n';

    let column = props.options.TableColumn;
    let field = [];
    let headerArr = [];
    for (let columnKey in column) {
        let {prop,label} = column[columnKey];
        field.push(prop);
        headerArr.push(label)
    }
    let arr = ViewDataArr.value;
    let str = '';
    for (let arrKey in arr) {
        let temp = arr[arrKey];
        let tempArr = [];
        for (let fieldKey in field) {
            tempArr.push(temp[field[fieldKey]])
        }
        str += tempArr.toString() + line
    }
    str = headerArr.toString() + line + str;
    common.downLoad(str,'Report.csv');
    emits()
}

const IsDeep = ref(false);
const changeDeep = () => {
    isLoading.value = true;
    emits('changeDeep',toRaw(IsDeep.value))
}

const SearchOptions = ref({
    view:false,
    str:'',
})
const Search = () => {
    SearchOptions.value.str = '';
    SearchOptions.value.view = true;
    console.log('Search')
}
const SearchData = () => {
    isLoading.value = true;
    SearchOptions.value.view = false;
    let str = toRaw(SearchOptions.value.str);
    let arr = ViewDataArr.value.filter(item=>{
        let bool = false;
        console.log(item)
        if (item?.leader){
            bool = common.checkExistString(item.leader,str);
            if (bool){
                return bool;
            }
        }
        if (item?.username){
            bool = common.checkExistString(item.username,str);
            if (bool){
                return bool;
            }
        }
        if (item?.agent){
            bool = common.checkExistString(item.agent,str);
            if (bool){
                return bool;
            }
        }
        return bool;
    })
    // console.log('arr',arr)
    SetTableDataArr(arr);
}
</script>

<style scoped>
.Common_Margin{
    margin-right: 10px;
}

</style>