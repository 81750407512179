<template>
    <div class="ReportPages">
        <el-pagination
            background
            layout="total,prev, pager, next,"
            :total=options.total
            style="margin-top: 10px"
            v-model:currentPage="options.page"
            :page-size=options.size
            @current-change="Change"
        />
    </div>
</template>

<script setup>
import {defineEmits,defineExpose,ref, toRaw} from "vue";

const emits = defineEmits(['PagesEvent'])
const options = ref({
    total:1,
    page:1,
    size:20,
})
const Change = () => {
    emits('PagesEvent',toRaw(options.value))
}
const recvOptions = (obj) => {
    options.value = obj
    // console.log('recvOptions',obj)
}
defineExpose({
    recvOptions
})

</script>

<style scoped>

</style>